import foxLogo from '.././images/splash/foxLogo.svg';
import FEN2 from '.././images/splash/FEN2.svg';
import NEC2 from '.././images/splash/NEC2.svg';
import strapline3 from '.././images/splash/strapline3.svg'
import facebookIcon from '.././images/header/facebookIcon.svg'
import linkedinIcon from '.././images/header/linkedinIcon.svg'
import instagramIcon from '.././images/header/instagramIcon.svg'
import comingSoonText from '.././images/splash/comingSoon.svg'

import '../styling/comingSoon.css';

function comingSoon() {
  return (
    <div className="splash">
      <header className="splash-header">
        <div className='coming-soon'>
          <img src={foxLogo} className="splash-logo" alt="Fennec Fox Logo" /> 
          <img src={FEN2} className="Fen-logo" alt="FEN" />
          <img src={NEC2} className="Nec-logo" alt="NEC" />
          <img src={strapline3} className="strapline-logo" alt="Create Design Repeat"/>
        </div>
      </header>
      <div className='pageText'>
        <img src={comingSoonText} className="coming-soon-text" alt="Coming Soon"/>
      </div>
      <footer className="footer">
        <div className="icons">
            <a className="link" href="https://www.facebook.com/fenneccreates" rel="noopener noreferrer" target="_blank">
                <img src={facebookIcon} className="facebook-icon" alt="Facebook"/>
            </a>
            <a className="link" href="https://www.linkedin.com/in/fenneccreates/" rel="noopener noreferrer" target="_blank">
                <img src={linkedinIcon} className="linkedin-icon" alt="LinkedIn"/>
            </a>
            <a className="link" href="https://www.instagram.com/fenneccreates/" rel="noopener noreferrer" target="_blank"> 
                <img src={instagramIcon} className="instagram-icon" alt="Instagram"/>
            </a>
        </div>
      </footer>
    </div>
  );
}

export default comingSoon;